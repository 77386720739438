:root {
  --black: #424240;
  --white: #ffffff;
  --primary: #431d93;
  --darkPrimary: #23c66b;
  --secondary: #22bbdd;
  --grey: #a1a4ab;
  --lightGrey: #c9cbd0;
  --lightestGrey: #e9eaec;
  --darkGrey: #646972;
  --green: #2bd978;
  --orange: #ffc260;
  --darkOrange: #ffb43c;
  --red: #df0909;
  --blue: #22bbdd;
  --butterflyBlue: #3699ff;
  --mustard: #e8a562;
  --lightGreen: #46ff78;

  --cardShadow: 4px 4px 20px rgba(0, 0, 0, 0.1);
}
