@import '../../../globalStyles.scss';

.create {
  width: 100%;
  font-family: Rubik;

  &__formContainer{
    width: 100%;
  }

  &__container {
    display: flex;
    gap: 32px;

    &__imageContainer {
      display: flex;
      flex-direction: column;
      gap: 8px;
      
      &__time{
        color: #424240;
      
        font-size: 14px;
      }
  
      &__image{
        width: 150px;
        aspect-ratio: 1/1;
        border: solid #A8A8A8 1px;
        border-radius: 4px;
        padding: 8px;
        object-fit: contain;
      }
      &__cameraIcon{
        width: 150px;
        aspect-ratio: 1/1;
        border: solid #A8A8A8 1px;
        border-radius: 4px;
        padding: 8px;
        object-fit: contain;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0.25;
      }
    }

   
  }

  &__heading {
    font-size: 32px;
    color: $primaryColor;
    margin-bottom: 16px;
  }
  &__form {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 16px;
    max-width: 600px;
  }
}

@media (max-width: $md-breakpoint) {
  .create__container {
    flex-wrap: wrap;
  }
}
