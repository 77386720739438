@font-face {
  font-family: Rubik;
  src: url('./ui/assets/Rubik-Regular.ttf') format('truetype');
}

@font-face {
  font-family: RubikBold;
  src: url('./ui/assets/Rubik-Medium.ttf') format('truetype');
}

@font-face {
  font-family: SourceCodePro;
  src: url('./ui/assets/SourceCodePro-Regular.ttf') format('truetype');
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');

html,
#root,
body,
.App {
  height: 100%;
  -webkit-tap-highlight-color: transparent;
}

::-webkit-scrollbar {
  height: 11px; 
  width: 11px;
}

/* Handle */
::-webkit-scrollbar-thumb {  
  background: rgba(168, 168, 168, 0.5); 
  border-radius: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(217, 217, 217, 0.5);
  border-radius: 6px;
}


html {
  font-size: 62.5%;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

.container-fluid {
  padding-left: 0;
  padding-right: 0;
}

/* reactflow */
.react-flow__edge-path {
  stroke: #443E99;
}

.react-flow__controls {
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  filter: none;
  box-shadow: none;
  /* bottom: 10%; */
  /* left: 40px; */
}

.react-flow__controls button :first-child {
  border: none;
  height: 55px;
  width: 55px;
}

.react-flow__controls :nth-child(n) {
  border-radius: 100%;
  background-color: #fffffd;
  height: 35px;
  width: 35px;
  filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.08));
  margin: 5px;
}

.react-flow__controls :nth-child(n) :first-child {
  border-radius: 0%;
  height: 35px;
  width: 35px;
}


.react-flow__zoom-in {
  background-color: #443E99;
  height: 100px;
  width: 100px;
}
a[class*='MenuItem'][class*='active'] .sidebar-fill path {
  stroke: transparent !important;
  fill: #e8a562 !important;
}

a[class*='MenuItem']:hover .sidebar-fill path {
  stroke: transparent !important;
  fill: #e8a562 !important;
}
