@import '../../globalStyles.scss';

.toaster {
  position: fixed;
  padding: 15px 5px;
  right: 24px;
  top: 32px;
  width: 350px;
  background: $whiteColor;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  display: flex;
  z-index: 99;
}

.success { 
  border-left: 19px solid #431D93;
}

.failure {
  border-left: 19px solid $alertColor;
}
