
  
  /* Demo Only */

  

  table {
    width: 100%;
    display: grid;
    overflow: auto;
    /* grid-template-columns:
    minmax(150px, 1fr)
    minmax(50px, 2fr)
    minmax(50px, 2fr)
    minmax(50px, 2fr)
    minmax(50px, 2fr)
    minmax(50px, 2fr);   */
  }
  
  table thead,
  table tbody,
  table tr {
    display: contents;
  }
  
  table th {
    color: red;
    position: relative;
    font-size: 18px;
  }
  

  
  .resize-handle {
    display: block;
    position: absolute;
    cursor: col-resize;
    width: 7px;
    right: 0;
    top: 0;
    z-index: 0;
    border-right: 2px solid transparent;
  }
  
  .resize-handle:hover {
    border-color:  #431D93;
  }
  
  .resize-handle.active {
    border-color: #E9EAEC
  }
  