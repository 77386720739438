@import '../../../../../globalStyles';
$nameColor: #22bbdd;

.tile {
  background: #f9f7ff;
  border-radius: 3px;
  padding: 5px 15px !important;
  margin-right: 10px !important;
}



.tile,
.name {
  color: $nameColor;
}