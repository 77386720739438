@import '../../../../globalStyles.scss';

.header {
  height: $headerHeight;
  background-color: $whiteColor;
  // box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  width: 100% !important;
}

.selectedWorkspace {
  font-family: 'Rubik';
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  color: #424240;
}

$activeBgColor: #fbfbfb;
$boxShadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
$width: 15rem;

.popup {
  background-color: $whiteColor;
  position: absolute;
  border: 1px solid $lightestGreyColor;
  border-radius: 4px;
  box-shadow: $boxShadow;
  min-width: $width;
  z-index: 100;
  top: 5rem;
  right: 0;
  text-align: end;
}

.popupItem {
  cursor: pointer;
  transition: 0.2s ease-in all;
  justify-content: end;
}

.popupItem:hover {
  background-color: $activeBgColor;
}
.your {
  font-family: 'Rubik';
  font-size: 14px;
  font-weight: 300;
  line-height: 16.59px;
}
