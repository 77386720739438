@import '../../../../globalStyles.scss';

.detailHeader {
  display: flex;
  align-items: center;
  font-family: Rubik;
  gap: 32px;
  margin-top: 32px;
  &__imageContainer {
    width: 150px;
    height: 150px;
    padding: 8px;
    aspect-ratio: 1 / 1;
    border: solid 1px #a8a8a8;
    border-radius: 4px;
    object-fit: contain;
  }
  &__header {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    &__heading {
      font-size: 32px;
      margin: 0;
      color: $primaryColor;
    }
    &__timestamp {
      font-size: 16px;
      color: #a8a8a8;
      margin: 0;
    }
  }

  &__footer {
    display: flex;
    gap: 16px;
    font-size: 14px;
    &__link {
      display: flex;
      color: $primaryColor;
      align-items: center;
      &__icon {
        width: 18px;
        height: 18px;
        stroke: $primaryColor;
      }
    }
  }
}
