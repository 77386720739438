@import '../../../globalStyles.scss';

.checkbox {
  height: 1.4rem;
  width: 1.4rem;
  border-radius: 2px;
  border: 1px solid $darkGreyColor;
  cursor: pointer;
  position: relative;
}

.checkedCheckbox {
  background: $greenColor;
  border: 1px solid $greenColor;
  &:before {
    position: absolute;
    content: '';
    display: inline-block;
    transform: rotate(45deg);
    height: 10px;
    width: 5px;
    border-bottom: 2px solid $whiteColor;
    border-right: 2px solid $whiteColor;
    left: 4px;
    bottom: 2px;
  }
}

.sortingBox {
  user-select: none;

  & p {
    transition: 0.2s ease-in all;
  }

  &:hover {
    & p {
      color: $darkGreyColor;
    }
  }
}
